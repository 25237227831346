import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import HeroBg from '../../../assets/img/herobg2.png';
import GscarabImg from '../../../assets/img/scarab-logo.png'
import Pharaoh from '../../../assets/img/3d-hero-img.png'
import { tomb as tombProd, tShare as tShareProd } from '../../../tomb-finance/deployments/deployments.mainnet.json';
  
const Background = styled.div`
background: url('${HeroBg}');
background-size: cover;
background-position: center;
width: 100%;
height: 100vh;
margin-top: -3rem !important;

@media(max-width: 960px) {
  margin-top: 0px !important;
}

@media(max-width: 650px) {
  margin-top: -3rem !important;
}
`

const StyledGridLeft = styled(Grid)`
margin-top: -6rem !important;

@media(max-width: 960px) {
  margin-top: 0px !important;
}
`

const StyledGridRight = styled(Grid)`

& > img {
  margin-top: -6rem !important;
  @media(max-width: 960px) {
    margin-top: 0px !important;
  }

  @media(max-width: 650px) {
    display: none;
  }
}

`

const StyledGrid = styled(Grid)`
padding: 3rem;
height: 100%;
align-content: center;
`

const HeaderText = styled(Typography)`

  @media(max-width: 960px) {
    text-align: left;
  }

  @media(max-width: 650px) {
    text-align: center;
  }
`

const ButtonGrid = styled(Grid)`

  @media(max-width: 650px) {
    place-content: center;
  }
`

// const ButtonPyramid = styled(Button)`
// text-shadow: none !important;

// ${localStorage.getItem('theme') === "theme-3" && 
// (`
// background-color: rgba(100,100,19,0.2) !important;
// border: 2px solid rgba(100,100,19,0.4) !important;
// color: #fff !important;

// &:hover {
//   background: rgba(100,100,19,0.4) !important;
//   border: 2px solid rgba(100,100,19,0.6) !important;
// }`)}

// ${localStorage.getItem('theme') === "theme-4" && 
// (`
// background-color: rgba(100,100,19,0.2) !important;
// border: 2px solid rgba(100,100,19,0.4) !important;
// color: #fff !important;

// &:hover {
//   background: rgba(100,100,19,0.4) !important;
//   border: 2px solid rgba(100,100,19,0.6) !important;
// }`)}
// `

const Hero: React.FC = () => {
  let tomb;
  tomb = tombProd;
  const buyTombAddress = 'https://swap.spiritswap.finance/#/exchange/swap/' + tomb.address;

    return (
      <Background>
        <StyledGrid container>
          <StyledGridLeft item lg={7} md={7} xs={12}>
            <Grid container style={{gridGap: '30px'}}>
              <HeaderText color='textPrimary' variant='h3'>
                Welcome to Scarab Finance
              </HeaderText>
              <HeaderText color='textSecondary' variant='h5'>
              The best algorithmic stablecoin on Fantom Opera, pegged to the price of 1 FTM via seigniorage.<br></br>

              Stake your SCARAB-FTM LP in the Sarcophagus to earn GSCARAB rewards. Then stake your earned GSCARAB in the Temple to earn more SCARAB!
              </HeaderText>
              <ButtonGrid container >
              <Grid item><HeaderText>
                <Button color="primary" target="_blank" href={buyTombAddress} variant="contained" style={{ marginRight: '10px', minWidth: '150px' }}>
                  Buy Scarab <img src={GscarabImg} width={24} height={24} style={{marginLeft: '5px'}}/>
                </Button></HeaderText>
                </Grid>
                <Grid item>
                  <HeaderText>
                <Button href='https://docs.scarab.finance' target="_blank" variant="contained" style={{ marginRight: '10px', minWidth: '150px' }}>
                  Documentation
                </Button>
                </HeaderText>
                </Grid>
              </ButtonGrid>
            </Grid>
          </StyledGridLeft>
          <StyledGridRight item lg={5} md={5} xs={12} style={{textAlign: 'center' }}>
            <img src={Pharaoh} width={400} height={400}/>
          </StyledGridRight>
        </StyledGrid>
      </Background>
    );
  }

export default Hero;