import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';

import ListItemLink from '../ListItemLink';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountButton from './AccountButton';
import styled from 'styled-components';

import GScarabLogo from '../../assets/img/gscarab-logo.png'
import ThemeButton from '../ThemeModal/ThemeButton';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    color: '#e0e3bd',
    'background':  localStorage.getItem('theme') === 'theme-3' ? 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3984944319524685) 100%)' : localStorage.getItem('theme') === 'theme-4' ? '#212121' : '#121212',
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: localStorage.getItem('theme') === 'theme-4' ? '0px' : '10px',
    marginBottom: '3rem',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    fontFamily: '"Caesar Dressing", cursive',
    fontSize: '30px',
    flexGrow: localStorage.getItem('theme') === 'theme-4' ? 'initial' : 1,
  },
  link: {
    fontFamily: localStorage.getItem('theme') === 'theme-4' ? 'Poppins' : 'Amaranth',
    textTransform: localStorage.getItem('theme') === 'theme-4' ? 'capitalize' : 'uppercase',
    color: localStorage.getItem('theme') === 'theme-4' ? '#fdffe6' : '#e0e3bd',
    fontSize: '14px',
    margin: theme.spacing(1, 2),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  brandLink: {
    textDecoration: 'none',
    color: localStorage.getItem('theme') === 'theme-4' ? '#fdffe6' : '#e0e3bd',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const StyledLink = styled(Link)`
${localStorage.getItem('theme') === 'theme-4' && `
&:hover {
  transition: 0.3s;
  border-bottom: solid 2px #e0e3bd;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
}
`}
`

const StyledMenu = styled(Menu)`
  //background-color: #121212;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;
  margin-top: 50px;

  && :hover {
    
  }
`

const StyledButtonMore = styled(Button)`
  textTransform: uppercase;
  color: #e0e3bd !important;
  fontSize: 14px;
  textDecoration: none;

  ${localStorage.getItem('theme') === 'theme-4' && `
    color: #fdffe6 !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
  `}
  
  &:hover {
    textDecoration: none;
    background-color: transparent !important;
  }
`

const StyledToolbar = styled(Toolbar)`
${localStorage.getItem('theme') === 'theme-4' && `
display: grid !important;
grid-template-columns: 17.5% 65% 17.5% !important;

height: 60px !important;
border-bottom: 2px solid rgba(133, 133, 133, 0.1) !important;
transform: translate3d(0px, 0px, 0px) !important;
// cursor: pointer !important;
`}
`

const StyledMenuTop = styled(Box)`
${localStorage.getItem('theme') === 'theme-4' && `
margin-right: 0px !important;
justify-self: center;
color: #fdffe6 !important;`}
${localStorage.getItem('theme') === 'theme-4' ? 'font-weight: 600;' : 'font-weight: 500;'}
`

const StyledBrand = styled(Link)`
${localStorage.getItem('theme') === 'theme-4' && `
display: flex;
align-items: center;
`}
`

const Nav = () => {
  const matches = useMediaQuery('(min-width:900px)');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElStaking, setAnchorElStaking] = React.useState(null);

  const handleClickStaking = (e: any) => {
    setAnchorElStaking(e.currentTarget);
  };

  const handleCloseStaking = () => {
    setAnchorElStaking(null);
  };

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <StyledToolbar className={classes.toolbar}>
        {matches ? (
          <>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              {/* <a className={ classes.brandLink } href="/">Scarab Finance</a> */}
              <StyledBrand to="/" color="inherit" className={classes.brandLink}>
                {localStorage.getItem('theme') === 'theme-4' && <img src={GScarabLogo} style={{marginRight: '10px'}} width={32} height={32} />}
                Scarab Finance
              </StyledBrand>
            </Typography>
            <StyledMenuTop mr={5}>
              <StyledLink color="textPrimary" to="/" className={classes.link}>
                Home
              </StyledLink>
              <StyledButtonMore aria-controls="menu-staking" aria-haspopup="true" onClick={handleClickStaking}>
                Staking ▼
              </StyledButtonMore>
              <StyledMenu id="menu-staking" anchorEl={anchorElStaking} keepMounted open={Boolean(anchorElStaking)} onClose={handleCloseStaking}>
              <StyledLink color="textPrimary" to="/sarcophagus" className={classes.link}>
                Sarcophagus
              </StyledLink>
              <StyledLink color="textPrimary" to="/temple" className={classes.link}>
                Temple
              </StyledLink>
              </StyledMenu>
                <StyledLink color="textPrimary" to="/obelisk" className={classes.link}>
                  Obelisk
                </StyledLink>
              
              <StyledButtonMore aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Info ▼
              </StyledButtonMore>
              <StyledMenu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <StyledLink color="textPrimary" to="/regulations" className={classes.link}>
                  Regulations
                </StyledLink>
                <a rel="noopener noreferrer" target="_blank" href="https://docs.scarab.finance" className={classes.link}>
                  Docs
                </a>
              </StyledMenu>
            </StyledMenuTop>
            <StyledDiv style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {/* <ThemeButton /> */}
            <AccountButton text="Connect" />
            </StyledDiv>
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Scarab Finance
            </Typography>

            <Drawer
              className={classes.drawer}
              onClose={handleDrawerClose}
              // onEscapeKeyDown={handleDrawerClose}
              // onBackdropClick={handleDrawerClose}
              variant="temporary"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              style={{background: '#000'}}
            >
              <div>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItemLink primary="Home" to="/" />
                <ListItemLink primary="Sarcophagus" to="/sarcophagus" />
                <ListItemLink primary="Temple" to="/temple" />
                {/* <ListItemLink primary="Desert" to="/desert" />
                <ListItemLink primary="Swap" to="/swap" /> */}
                <ListItemLink primary="Obelisk" to="/obelisk" />
                <ListItemLink primary="Regulations" to="/regulations" />
                <ListItem button component="a" rel="noopener noreferrer" target="_blank" href="https://docs.scarab.finance">
                  <ListItemText>Docs</ListItemText>
                </ListItem>
                <ListItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ThemeButton/>
                  <AccountButton text="Connect" />
                </ListItem>
              </List>
            </Drawer>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

const StyledDiv = styled(Box)`
${localStorage.getItem('theme') === 'theme-4' && `
justify-content: flex-end;
`}
`

export default Nav;
