function setTheme(themeName) {
    localStorage.setItem('theme', themeName);
    document.documentElement.className = themeName;
}

function keepTheme() {
  if (localStorage.getItem('theme')) {
    // if (localStorage.getItem('theme') === 'theme-1') {
    //   setTheme('theme-1');
    // }
    // if (localStorage.getItem('theme') === 'theme-2') {
    //   setTheme('theme-2')
    // }
    // if (localStorage.getItem('theme') === 'theme-3') {
    //   setTheme('theme-3')
    // }
    // if (localStorage.getItem('theme') === 'theme-4') {
    //   setTheme('theme-4')
    // }
    setTheme('theme-4')
  } else {
    setTheme('theme-4')
  }
}

module.exports = {
  setTheme,
  keepTheme
}