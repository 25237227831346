import React from 'react';
import { Container } from '@material-ui/core';
import useEagerConnect from '../../hooks/useEagerConnect';

import Nav from '../Nav'
import styled from 'styled-components';
import FooterV2 from '../FooterV2';
import Hero from '../../views/Home/components/Hero';

const OuterContainer = styled.div`
display: grid;
grid-template-columns: 17% 83%;
`

const PageContainer = styled.div`
display: grid;
grid-template-rows: 40px 1fr 40px;
`

const StyledContainer = styled(Container)`
${localStorage.getItem('theme') === "theme-4" && `
// padding-top: 0px !important;
// max-width: 100vw !important;
// padding-left: 0px !important;
// padding-right: 0px !important;
`}
`
interface HeroProps {
  showHero?: boolean
}
const Page: React.FC<HeroProps> = ({ children, showHero }) => {
  useEagerConnect();
  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
        <Nav />
        {showHero && <Hero/>}
        <StyledContainer maxWidth="lg" style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
          {children}
        </StyledContainer>
        <FooterV2 />
    </div>
  );
};

export default Page;
