import React, { useEffect, useState } from 'react';
import './styles.css';
import { setTheme } from './Themes';
import styled from 'styled-components';

const StyledFlex = styled.div`
position: relative;
width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
`

interface ToggleProps {
  toggleToShow: number
}

const Toggle: React.FC<ToggleProps> = ({ toggleToShow }) => {
    // false = 1 mode because of the way I wrote the CSS
    const [active, setActive] = useState(false)
    const [active2, setActive2] = useState(false)
    const [active3, setActive3] = useState(false)
    const [active4, setActive4] = useState(false)
    // the opposite, for screen readers
    const [ariaActive, setAriaActive] = useState(false)
    const [ariaActive2, setAriaActive2] = useState(false)
    const [ariaActive3, setAriaActive3] = useState(false)
    const [ariaActive4, setAriaActive4] = useState(false)
    let theme = localStorage.getItem('theme')

    const changeThemeAndToggle = (id: any) => {

      switch(id) {
        case 1:
          setTheme('theme-1')
          setActive(true)
          setAriaActive(true)
          setActive2(false)
          setAriaActive2(false)
          setActive3(false)
          setAriaActive3(false)
          setActive4(false)
          setAriaActive4(false)
          break;
        case 2:
          setTheme('theme-2')
          setActive(false)
          setAriaActive(false)
          setActive2(true)
          setAriaActive2(true)
          setActive3(false)
          setAriaActive3(false)
          setActive4(false)
          setAriaActive4(false)
          break;
        case 3:
          setTheme('theme-3')
          setActive(false)
          setAriaActive(false)
          setActive2(false)
          setAriaActive2(false)
          setActive3(true)
          setAriaActive3(true)
          setActive4(false)
          setAriaActive4(false)
          break;
        case 4:
          setTheme('theme-4')
          setActive(false)
          setAriaActive(false)
          setActive2(false)
          setAriaActive2(false)
          setActive3(false)
          setAriaActive3(false)
          setActive4(true)
          setAriaActive4(true)
          break;
        default:
          setTheme('theme-1')
          setActive(true)
          setAriaActive(true)
          setActive2(false)
          setAriaActive2(false)
          setActive3(false)
          setAriaActive3(false)
          setActive4(false)
          setAriaActive4(false)
          break;
      }
    }

    const handleOnClick = () => {
      changeThemeAndToggle(1)
      window.location.reload();
    }

    const handleKeypress = () => {
      changeThemeAndToggle(1)
    }

    const handleOnClick2 = () => {
      changeThemeAndToggle(2)
      window.location.reload();
    }

    const handleKeypress2 = () => {
      changeThemeAndToggle(2)
    }

    const handleOnClick3 = () => {
      changeThemeAndToggle(3)
      window.location.reload();
    }

    const handleKeypress3 = () => {
      changeThemeAndToggle(3)
    }

    const handleOnClick4 = () => {
      changeThemeAndToggle(4)
      window.location.reload();
    }

    const handleKeypress4 = () => {
      changeThemeAndToggle(4)
    }

    useEffect(() => {
      if (localStorage.getItem('theme') === 'theme-1') {
        setTheme('theme-1')
        setActive(true)
        setAriaActive(true)
        setActive2(false)
        setAriaActive2(false)
        setActive3(false)
        setAriaActive3(false)
        setActive4(false)
        setAriaActive4(false)
      } 
      if (localStorage.getItem('theme') === 'theme-2') {
        setTheme('theme-2')
        setActive(false)
        setAriaActive(false)
        setActive2(true)
        setAriaActive2(true)
        setActive3(false)
        setAriaActive3(false)
        setActive4(false)
        setAriaActive4(false)
      }
      if (localStorage.getItem('theme') === 'theme-3') {
        setTheme('theme-3')
        setActive(false)
        setAriaActive(false)
        setActive2(false)
        setAriaActive2(false)
        setActive3(true)
        setAriaActive3(true)
        setActive4(false)
        setAriaActive4(false)
      }
      if (localStorage.getItem('theme') === 'theme-4') {
        setTheme('theme-4')
        setActive(false)
        setAriaActive(false)
        setActive2(false)
        setAriaActive2(false)
        setActive3(false)
        setAriaActive3(false)
        setActive4(true)
        setAriaActive4(true)
      }
    }, [theme])

    return (
      <StyledFlex>
        {toggleToShow === 1 && (
        <div className="check">
          <input aria-label="Change Theme" role="switch" aria-checked={ariaActive} onKeyPress={handleKeypress} type="checkbox" id="check" className="check" onClick={handleOnClick} checked={active} readOnly />
          <label htmlFor="check">
          </label>
        </div>
        )}
        {toggleToShow === 2 && (
        <div className="check2">
          <input aria-label="Change Theme" role="switch" aria-checked={ariaActive2} onKeyPress={handleKeypress2} type="checkbox" id="check2" className="check2" onClick={handleOnClick2} checked={active2} readOnly />
          <label htmlFor="check2">
        </label>
        </div>
        )}
        {toggleToShow === 3 && (
        <div className="check3">
          <input aria-label="Change Theme" role="switch" aria-checked={ariaActive3} onKeyPress={handleKeypress3} type="checkbox" id="check3" className="check3" onClick={handleOnClick3} checked={active3} readOnly />
          <label htmlFor="check3">
        </label>
        </div>
        )}
        {toggleToShow === 4 && (
        <div className="check4">
          <input aria-label="Change Theme" role="switch" aria-checked={ariaActive4} onKeyPress={handleKeypress4} type="checkbox" id="check4" className="check4" onClick={handleOnClick4} checked={active4} readOnly />
          <label htmlFor="check4">
        </label>
        </div>
        )}
      </StyledFlex>
    )
}

export default Toggle;