import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Link } from '@material-ui/core';
import TwitterImage from '../../assets/img/twitter.svg';
// import GithubImage from '../../assets/img/github.svg';
import TelegramImage from '../../assets/img/telegram.svg';
import DiscordImage from '../../assets/img/discord.svg';
import YoutubeImage from '../../assets/img/youtube.svg';
import ShopImg from '../../assets/img/shop-logo.svg';
import MediumImage from '../../assets/img/medium.svg';
import CertikImage from '../../assets/img/certiklogo.png';

const StyledItem = styled(Grid)`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
`

const useStyles = makeStyles((theme) => ({
  footer: {
    position: (localStorage.getItem('theme') === 'theme-4' ? 'absolute' : 'relative'),
    bottom: '0',
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
    color: 'white',
    backgroundColor: (localStorage.getItem('theme') === 'theme-4' ? '#212121' : '#121212'),
    textAlign: 'center',
    height: '1.3rem',
    top: (localStorage.getItem('theme') === 'theme-4' ? 'initial' : '-10px'),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  link: {
    width: '24px',
    height: '24px',
    display: 'inline',
    marginLeft: '20px',
  },

  img: {
    width: '24px',
    height: '24px',
  },
}));

const FooterV2 = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container>
          <StyledItem item xs={2}>
            <Typography variant="body2" color="textSecondary" align="left">
              {'Copyright © '}
              <Link color="inherit" href="/">
                Scarab Finance
              </Link>{' '}
              {new Date().getFullYear()}{' '}
              
            </Typography>
          </StyledItem>
          <StyledItem item xs={3}>
            <a                
              href="https://www.certik.com/projects/scarab-finance"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}><img alt="certik" src={CertikImage} width={24} height={24} /></a>
          </StyledItem>
          <Grid item xs={7} style={{ textAlign: 'right' }}>
            <a
              href="https://twitter.com/Scarab_Finance"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="twitter" src={TwitterImage} className={classes.img} />
            </a>
            <a
              href="https://medium.com/@scarab_finance"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="medium" src={MediumImage} className={classes.img} />
            </a>
            <a href="https://t.me/scarabfinance" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <img alt="telegram" src={TelegramImage} className={classes.img} />
            </a>
            <a href="https://discord.gg/G2n5eyHWG6" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <img alt="discord" src={DiscordImage} className={classes.img} />
            </a>
            <a
              href="https://shop.scarab.finance/"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="shop" src={ShopImg} className={classes.img} />
            </a> 
            <a
              href="https://www.youtube.com/channel/UCOMfccTRO06zOFE47Wrrqhg"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="youtube" src={YoutubeImage} className={classes.img} />
            </a> 
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default FooterV2;
