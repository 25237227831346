import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useWallet } from 'use-wallet';
import useModal from '../../hooks/useModal';
import WalletProviderModal from '../WalletProviderModal';
import ThemeModal from './ThemeModal';
import PyramidIcon from '../../assets/img/pyramid-svg-icon.svg'

interface AccountButtonProps {
  text?: string;
}

const ThemeButton: React.FC<AccountButtonProps> = ({ text }) => {
  const { account } = useWallet();
  const [onPresentThemeModal] = useModal(<ThemeModal />);

  const [isWalletProviderOpen, setWalletProviderOpen] = useState(false);

  const handleWalletProviderOpen = () => {
    setWalletProviderOpen(true);
  };

  const handleWalletProviderClose = () => {
    setWalletProviderOpen(false);
  };

  const buttonText = text ? text : 'Unlock';

  return (
    <div>
        <Button variant="text" onClick={onPresentThemeModal}>
          <img src={PyramidIcon} width={24} height={24}></img>
        </Button>
    </div>
  );
};

export default ThemeButton;
