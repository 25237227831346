import React from 'react';
import styled from 'styled-components';
import CardBG from '../../assets/img/CardBackground.png'
import CardBGHome2 from '../../assets/img/CardBackground-Home2.png'
import CardBGHome3 from '../../assets/img/CardBackground-3.png'

const CardPyramid: React.FC = ({ children }) => <StyledCard>{children}</StyledCard>;

const StyledCard = styled.div`
   ${localStorage.getItem('theme') === "theme-1" && 
    (`
    background: url('${CardBG}');
    color: #fdffe6 !important;
    display: flex;
    flex: 1;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    border-radius: 5px;
    font-weight: bold;
    flex-direction: column;
    border: solid 3px #38332Eaa;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
    `)}
    ${localStorage.getItem('theme') === "theme-2" && 
    (`
    background: url('${CardBGHome2}');
    background-size: cover;
    color: #fdffe6 !important;
    display: flex;
    flex: 1;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    border-radius: 5px;
    font-weight: bold;
    flex-direction: column;
    border: solid 3px #38332Eaa;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
    `)}
    ${localStorage.getItem('theme') === "theme-3" && 
    (`
      // background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(69,62,12,0.8606793059020483) 100%, rgba(33,33,33,0.28925073447347693) 100%, rgba(0,0,0,0.4573179613642332) 100%);
      background: url('${CardBGHome3}'); 
      color: #fdffe6 !important;
      display: flex;
      flex: 1;
      border-radius: 15px;
      font-weight: bold;
      flex-direction: column;
      border: solid 3px rgba(69,62,12,0.2);
      // border: solid 3px #38332Eaa;
      text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    `)}
    ${localStorage.getItem('theme') === "theme-4" && 
    (`
      background: #212121; 
      color: #fdffe6 !important;
      display: flex;
      flex: 1;
      border-radius: 12.5px;
      font-weight: bold;
      flex-direction: column;
      border: solid 3px rgba(69,62,12,0.4);
      text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    `)}
    ${localStorage.getItem('theme') === null && 
    (`
      background: #212121; 
      color: #fdffe6 !important;
      display: flex;
      flex: 1;
      border-radius: 12.5px;
      font-weight: bold;
      flex-direction: column;
      border: solid 3px rgba(69,62,12,0.4);
      text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    `)}
`;

export default CardPyramid;
