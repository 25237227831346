// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';
import { AutoCompounderInfo } from './tomb-finance/types';

const configurations: { [env: string]: Configuration } = {
  production: {
    chainId: ChainId.FTMTESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    externalTokens: {
      WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18],
      FUSDT: ['0xb7f24e6e708eabfaa9e64b40ee21a5adbffb51d6', 6],
      BOO: ['0x14f0C98e6763a5E13be5CE014d36c2b69cD94a1e', 18],
      ZOO: ['0x2317610e609674e53D9039aaB85D8cAd8485A7c5', 0],
      SHIBA: ['0x39523112753956d19A3d6a30E758bd9FF7a8F3C0', 9],
      'USDT-FTM-LP': ['0xE7e3461C2C03c18301F66Abc9dA1F385f45047bA', 18],
      'SCARAB-FTM-LP': ['0x13Fe199F19c8F719652985488F150762A5E9c3A8', 18],
      'GSCARAB-FTM-LP': ['0x20bc90bB41228cb9ab412036F80CE4Ef0cAf1BD5', 18],
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  development: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ankr.com/fantom/',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18],
      TOMB: ['0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18],
      TSHARE: ['0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37', 18],
      FUSDT: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6], // This is actually usdc on mainnet not fusdt
      BOO: ['0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      SinSPIRIT: ['0x749F2B95f950c4f175E17aa80aa029CC69a30f09', 18],
      SPIRIT: ['0x5Cc61A78F164885776AA610fb0FE1257df78E59B', 18],
      LQDR: ['0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9', 18],
      fUSDT: ['0x049d68029688eAbF473097a2fC38ef61633A3C7A', 6],
      BTC: ['0x321162Cd933E2Be498Cd2267a90534A804051b11', 8],
      USDC: ['0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', 6],
      ETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18],
      FRAX: ['0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355', 18],
      MAI: ['0xfB98B335551a418cD0737375a2ea0ded62Ea213b', 18],
      DAI: ['0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E', 18],
      'CRE8R': ['0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0', 18],
      'USDT-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],
      'SCARAB-FTM-LP': ['0x78e70eF4eE5cc72FC25A8bDA4519c45594CcD8d4', 18],
      'GSCARAB-FTM-LP': ['0x27228140D72a7186F70eD3052C3318f2D55c404d', 18],
      'TOMB-FTM-LP': ['0x2A651563C9d3Af67aE0388a5c8F89b867038089e', 18],
      'TSHARE-FTM-LP': ['0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2', 18],
      'LQDR-FTM-LP': ['0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2', 18],
      'CRE8R-FTM-LP': ['0x459e7c947E04d73687e786E4A48815005dFBd49A', 18],
      'SPIRIT-FTM-LP': ['0x30748322B6E34545DBe0788C421886AEB5297789', 18],
      'fUSDT-FTM-LP': ['0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4', 18],
      'USDC-FTM-LP': ['0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D', 18],
      'BTC-FTM-LP': ['0x279b2c897737a50405ED2091694F225D83F2D3bA', 18],
      'ETH-FTM-LP': ['0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e', 18],
      'FRAX-FTM-LP': ['0x7ed0cdDB9BB6c6dfEa6fB63E117c8305479B8D7D', 18],
      'MAI-FTM-LP': ['0x51Eb93ECfEFFbB2f6fE6106c4491B5a0B944E8bd', 18],
      'SPIRIT-SINSPIRIT-LP': ['0x66f0a5fd09df38416ffec46735b4cb752c43fd13', 18],

      BOMB: ['0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378', 18],
      BIFI: ['0xd6070ae98b8069de6B494332d1A1a81B6179D960', 18],
      DEI: ['0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3', 18],
      gOHM: ['0x91fa20244Fb509e8289CA630E5db3E9166233FDc', 18],
      ATLAS: ['0x92df3eaBf7c1c2A6b3D5793f6d53778eA78c48b2', 18],
      DEUS: ['0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44', 18],
      'BOMB-FTM-LP': ['0x11d7fD8Deb7F37EA97218F70550E03fe6683df3D', 18],
      'BIFI-FTM-LP': ['0xc28cf9aeBfe1A07A27B3A4d722C841310e504Fe3', 18],
      'DEI-USDC-LP': ['0x8eFD36aA4Afa9F4E157bec759F1744A7FeBaEA0e', 18],
      'gOHM-FTM-LP': ['0xae9BBa22E87866e48ccAcFf0689AFaa41eB94995', 18],
      'ATLAS-FTM-LP': ['0xFFcF183126dF14EC4E59409bAb431885ccEEb1C2', 18],
      'SCARAB-GSCARAB-LP': ['0x8e38543d4c764DBd8f8b98C73407457a3D3b4999', 18],
      'DEUS-FTM-LP': ['0x2599Eba5fD1e49F294C76D034557948034d6C96E', 18],



      '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8': ['0x12FC98B8545fb5BA4824Ef948a5e5423190363a8', 18],
      '0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84': ['0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84', 18],
      '0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19': ['0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19', 18],
      '0x8792DB08e2075b49AA1c7269da1CB1117C24F815': ['0x8792DB08e2075b49AA1c7269da1CB1117C24F815', 18],
      '0x798924bcE0f5380a85706ddb70C59F59217f375e': ['0x798924bcE0f5380a85706ddb70C59F59217f375e', 18],
      '0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48': ['0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48', 18],
      /* NEW */
      '0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6': ['0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6', 18],
      '0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7': ['0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7', 18],
      '0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67': ['0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67', 18],
      '0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF': ['0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF', 18],
      '0x6f2407057dae200E688384eC372F169A01Ed7E70': ['0x6f2407057dae200E688384eC372F169A01Ed7E70', 18],
      '0x6896352948619132c271C3B302d8DD89F61DB8b2': ['0x6896352948619132c271C3B302d8DD89F61DB8b2', 18],
      '0xcae8b5ada97c51860fabbb323736c49ecc638cfc': ['0xcae8b5ada97c51860fabbb323736c49ecc638cfc', 18],
      '0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB': ['0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB', 18],
      '0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D': ['0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D', 18],
      '0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49': ['0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49', 18],
      '0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431': ['0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431', 18],
      '0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c': ['0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c', 18],
      '0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8': ['0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8', 18],
      '0x9D7DECe83405D5F41F970Fc5197C1732962960B9': ['0x9D7DECe83405D5F41F970Fc5197C1732962960B9', 18],
      '0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4': ['0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4', 18],
      '0xaA68e97bab8bCFea72E1371496B804386F930f79': ['0xaA68e97bab8bCFea72E1371496B804386F930f79', 18],

      '0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04': ['0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04', 18],
      '0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0': ['0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0', 18],
      '0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe': ['0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe', 18],
      '0x8C1BbD5AabA754c8715eB039966b0E9dFa564368': ['0x8C1BbD5AabA754c8715eB039966b0E9dFa564368', 18],
      '0x42D3e161319bd5b014c6b7f90fc34D2324456569': ['0x42D3e161319bd5b014c6b7f90fc34D2324456569', 18],
      '0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73': ['0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73', 18],
      '0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E': ['0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E', 18],
      '0x714f1b9b581826aa87e885e366d5315d6a676102': ['0x714f1b9b581826aa87e885e366d5315d6a676102', 18],
      '0x500445604Ceac98a5E889B064548BfBE0407Bf8a': ['0x500445604Ceac98a5E889B064548BfBE0407Bf8a', 18],
      '0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5': ['0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5', 18],
      '0x46A551E76e8E9e46D7983707925CF509A07bbDdC': ['0x46A551E76e8E9e46D7983707925CF509A07bbDdC', 18],
      '0xc5f901A80217A82D449d98012270528378E3B723': ['0xc5f901A80217A82D449d98012270528378E3B723', 18],
      '0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB': ['0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB', 18],
      '0x0b84df4e6974099a3eaf678f4379cb12d5c84f64': ['0x0b84df4e6974099a3eaf678f4379cb12d5c84f64', 18],
    },
    baseLaunchDate: new Date('2021-12-27 16:00:00Z'),
    bondLaunchesAt: new Date('2021-12-27T18:00:00Z'),
    masonryLaunchesAt: new Date('2021-12-27T18:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding SCARAB
        - 2 = LP asset staking rewarding GSCARAB
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  TombFtmRewardPool: {
    name: 'Earn SCARAB by FTM',
    poolId: 0,
    sectionInUI: 0,
    contract: 'TombFtmRewardPool',
    depositTokenName: 'WFTM',
    earnTokenName: 'SCARAB',
    finished: false,
    sort: 1,
    closedForStaking: true,
  },
  TombBooRewardPool: {
    name: 'Earn SCARAB by BOO',
    poolId: 1,
    sectionInUI: 0,
    contract: 'TombBooGenesisRewardPool',
    depositTokenName: 'BOO',
    earnTokenName: 'SCARAB',
    finished: false,
    sort: 2,
    closedForStaking: true,
  },
  TombShibaRewardPool: {
    name: 'Earn SCARAB by SHIBA',
    poolId: 2,
    sectionInUI: 0,
    contract: 'TombShibaGenesisRewardPool',
    depositTokenName: 'SHIBA',
    earnTokenName: 'SCARAB',
    finished: false,
    sort: 3,
    closedForStaking: true,
  },
  TombZooRewardPool: {
    name: 'Earn SCARAB by ZOO',
    poolId: 3,
    sectionInUI: 0,
    contract: 'TombZooGenesisRewardPool',
    depositTokenName: 'ZOO',
    earnTokenName: 'SCARAB',
    finished: false,
    sort: 4,
    closedForStaking: true,
  },
  TombFtmLPTombRewardPool: {
    name: 'Earn SCARAB by SCARAB-FTM LP',
    poolId: 0,
    sectionInUI: 1,
    contract: 'TombFtmLpTombRewardPool',
    depositTokenName: 'SCARAB-FTM-LP',
    earnTokenName: 'SCARAB',
    finished: false,
    sort: 5,
    closedForStaking: true,
  },
  // TombFtmLPTombRewardPoolOld: {
  //   name: 'Earn SCARAB by SCARAB-FTM LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'TombFtmLpTombRewardPoolOld',
  //   depositTokenName: 'SCARAB-FTM-LP',
  //   earnTokenName: 'SCARAB',
  //   finished: true,
  //   sort: 9,
  //   closedForStaking: true,
  // },
  ScarabFtmLPGScarabRewardPool: {
    name: 'Earn GSCARAB by SCARAB-FTM LP',
    poolId: 0,
    sectionInUI: 2,
    contract: 'ScarabFtmLPGScarabRewardPool',
    depositTokenName: 'SCARAB-FTM-LP',
    earnTokenName: 'GSCARAB',
    finished: false,
    sort: 6,
    closedForStaking: false,
  },
  GscarabFtmLPGScarabRewardPool: {
    name: 'Earn GSCARAB by GSCARAB-FTM LP',
    poolId: 1,
    sectionInUI: 2,
    contract: 'GscarabFtmLPGScarabRewardPool',
    depositTokenName: 'GSCARAB-FTM-LP',
    earnTokenName: 'GSCARAB',
    finished: false,
    sort: 7,
    closedForStaking: false,
  },
};

export const autoCompounderDefinitions: { [contractName: string]: AutoCompounderInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding SCARAB
        - 2 = LP asset staking rewarding GSCARAB
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  ScarabAutocompounder: {
    name: 'Scarab AutoCompounder',
    poolId: 0,
    sectionInUI: 4,
    contract: 'ScarabAutocompounder',
    depositTokenName: 'SCARAB-FTM-LP',
    earnTokenName: 'GSCARAB',
    vaultContract: 'ScarabVaultAutocompounder',
    autocompounderContract: 'ScarabAutocompounder',
    masterChefContract: 'ScarabFtmLPGScarabRewardPool',
    pairTokenName1: 'SCARAB',
    pairTokenName2: 'WFTM',
    linkVault: '0x12FC98B8545fb5BA4824Ef948a5e5423190363a8',
    linkCompounder: '0x2CD89B6144D1928441F6a00D8738Ad46D4C0E394',
    finished: false,
    sort: 8,
    closedForStaking: false,
  },
  TombAutocompounder: {
    name: 'Tomb AutoCompounder',
    poolId: 1,
    sectionInUI: 4,
    contract: 'TombAutocompounder',
    depositTokenName: 'TOMB-FTM-LP',
    earnTokenName: 'TSHARE',
    vaultContract: 'TombVaultAutocompounder',
    autocompounderContract: 'TombAutocompounder',
    masterChefContract: 'TombFtmLPTShareRewardPool',
    pairTokenName1: 'TOMB',
    pairTokenName2: 'WFTM',
    linkVault: '0x8DD4D2eb0693924B98BFEaE39e5C3cE5B0e12c84',
    linkCompounder: '0xFeaaBde6488176C69CD694Ddc35A5A31718187C0',
    finished: false,
    sort: 9,
    closedForStaking: false,
  },
  SpiritSinspiritAutocompounder: {
    name: 'SPIRIT-SinSPIRIT AutoCompounder',
    poolId: 69,
    sectionInUI: 4,
    contract: 'SpiritSinspiritAutocompounder',
    depositTokenName: 'SPIRIT-SINSPIRIT-LP',
    earnTokenName: 'SPIRIT',
    vaultContract: 'SpiritSinspiritVaultAutocompounder',
    autocompounderContract: 'SpiritSinspiritAutocompounder',
    masterChefContract: 'SpiritSwapMasterChef',
    pairTokenName1: 'SPIRIT',
    pairTokenName2: 'SinSPIRIT',
    linkVault: '0x9D7DECe83405D5F41F970Fc5197C1732962960B9',
    linkCompounder: '0xd598D195e77E595d70270DCCB33f6AB3c7b3B4b8',
    finished: false,
    sort: 18,
    closedForStaking: false,
  },
  SinSPIRITAutocompounder: {
    name: 'LQDR-FTM-LP AutoCompounder',
    poolId: 0,
    sectionInUI: 5,
    contract: 'SinSPIRITAutocompounder',
    depositTokenName: 'LQDR-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'SinSPIRITVaultAutocompounder',
    autocompounderContract: 'SinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'LQDR',
    pairTokenName2: 'WFTM',
    linkVault: '0x8792DB08e2075b49AA1c7269da1CB1117C24F815',
    linkCompounder: '0x61BDB074c39a227f9f79588C1e3Cc904f38b9C19',
    finished: false,
    sort: 10,
    closedForStaking: false,
  },
  SpiritFTMSinSPIRITAutocompounder: {
    name: 'SPIRIT-FTM-LP AutoCompounder',
    poolId: 1,
    sectionInUI: 5,
    contract: 'SpiritFTMSinSPIRITAutocompounder',
    depositTokenName: 'SPIRIT-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'SpiritFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'SpiritFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'SPIRIT',
    pairTokenName2: 'WFTM',
    linkVault: '0xfFC07E2Bd4378756c96ba5F915cB731eFB052D48',
    linkCompounder: '0x798924bcE0f5380a85706ddb70C59F59217f375e',
    finished: false,
    sort: 11,
    closedForStaking: false,
  },
  /* NEW ONES */
  fUSDTFTMSinSPIRITAutocompounder: {
    name: 'fUSDT-FTM-LP AutoCompounder',
    poolId: 2,
    sectionInUI: 5,
    contract: 'fUSDTFTMSinSPIRITAutocompounder',
    depositTokenName: 'fUSDT-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'fUSDTFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'fUSDTFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'fUSDT',
    pairTokenName2: 'WFTM',
    linkVault: '0x8bf7472BDcE91035E95Cca95cB51FEeaAdC52AE7',
    linkCompounder: '0xA0E52d51C83A46432a18eB78B643A0bA2b34fbe6',
    finished: false,
    sort: 12,
    closedForStaking: false,
  },
  BTCFTMSinSPIRITAutocompounder: {
    name: 'BTC-FTM-LP AutoCompounder',
    poolId: 3,
    sectionInUI: 5,
    contract: 'BTCFTMSinSPIRITAutocompounder',
    depositTokenName: 'BTC-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'BTCFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'BTCFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'BTC',
    pairTokenName2: 'WFTM',
    linkVault: '0x24F0352Bab77f73E99cd5253a6771FDeD90c00fF',
    linkCompounder: '0x63fB0BC4a727F40Cdb41571B0D6254866fd36C67',
    finished: false,
    sort: 13,
    closedForStaking: false,
  },
  USDCFTMSinSPIRITAutocompounder: {
    name: 'USDC-FTM-LP AutoCompounder',
    poolId: 4,
    sectionInUI: 5,
    contract: 'USDCFTMSinSPIRITAutocompounder',
    depositTokenName: 'USDC-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'USDCFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'USDCFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'USDC',
    pairTokenName2: 'WFTM',
    linkVault: '0x6896352948619132c271C3B302d8DD89F61DB8b2',
    linkCompounder: '0x6f2407057dae200E688384eC372F169A01Ed7E70',
    finished: false,
    sort: 14,
    closedForStaking: false,
  },
  ETHFTMSinSPIRITAutocompounder: {
    name: 'ETH-FTM-LP AutoCompounder',
    poolId: 5,
    sectionInUI: 5,
    contract: 'ETHFTMSinSPIRITAutocompounder',
    depositTokenName: 'ETH-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'ETHFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'ETHFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'ETH',
    pairTokenName2: 'WFTM',
    linkVault: '0xb8Cec5c57F1D6004ADC48DdDfa848ce8E164EFfB',
    linkCompounder: '0xcae8b5ada97c51860fabbb323736c49ecc638cfc',
    finished: false,
    sort: 15,
    closedForStaking: false,
  },
  FRAXFTMSinSPIRITAutocompounder: {
    name: 'FRAX-FTM-LP AutoCompounder',
    poolId: 6,
    sectionInUI: 5,
    contract: 'FRAXFTMSinSPIRITAutocompounder',
    depositTokenName: 'FRAX-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'FRAXFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'FRAXFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'FRAX',
    pairTokenName2: 'WFTM',
    linkVault: '0x6185B40CB5D35a1346Fb12dA28F8Cf5e81b9dE49',
    linkCompounder: '0x6ABd7c99E0Fc531CaEd7Fc28da6940c07545200D',
    finished: false,
    sort: 16,
    closedForStaking: false,
  },
  MAIFTMSinSPIRITAutocompounder: {
    name: 'MAI-FTM-LP AutoCompounder',
    poolId: 7,
    sectionInUI: 5,
    contract: 'MAIFTMSinSPIRITAutocompounder',
    depositTokenName: 'MAI-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'MAIFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'MAIFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'MAI',
    pairTokenName2: 'WFTM',
    linkVault: '0xB47AE093BE781Cc5C5e3525207785A4FA8cB989c',
    linkCompounder: '0x63F4138b5c5BD7E8Aa5B2e99efb01868f0FFa431',
    finished: false,
    sort: 17,
    closedForStaking: false,
  },
  CreatorFTMSinSPIRITAutocompounder: {
    name: 'CRE8R-FTM-LP AutoCompounder',
    poolId: 8,
    sectionInUI: 5,
    contract: 'CreatorFTMSinSPIRITAutocompounder',
    depositTokenName: 'CRE8R-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'CreatorFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'CreatorFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'CRE8R',
    pairTokenName2: 'WFTM',
    linkVault: '0xEbcf7659E41cFa1B80aA2Bebf06Aea245E0124f4',
    linkCompounder: '0xaA68e97bab8bCFea72E1371496B804386F930f79',
    finished: false,
    sort: 19,
    closedForStaking: false,
  },
  // BombFTMSinSPIRITAutocompounder: {
  //   name: 'BOMB-FTM-LP AutoCompounder',
  //   poolId: 9,
  //   sectionInUI: 5,
  //   contract: 'BombFTMSinSPIRITAutocompounder',
  //   depositTokenName: 'BOMB-FTM-LP',
  //   earnTokenName: 'SinSPIRIT',
  //   vaultContract: 'BombFTMSinSPIRITVaultAutocompounder',
  //   autocompounderContract: 'BombFTMSinSPIRITAutocompounder',
  //   masterChefContract: 'SinSPIRITRewardPool',
  //   pairTokenName1: 'BOMB',
  //   pairTokenName2: 'WFTM',
  //   linkVault: '0x49E8619991f9D52c16D71E0505eEB8Bc9C6dda04',
  //   linkCompounder: '0x374EF9BC22BB718fA0a8A53230c5e2aE16c722e0',
  //   finished: false,
  //   sort: 20,
  //   closedForStaking: false,
  // },
  BifiFTMSinSPIRITAutocompounder: {
    name: 'BIFI-FTM-LP AutoCompounder',
    poolId: 10,
    sectionInUI: 5,
    contract: 'BifiFTMSinSPIRITAutocompounder',
    depositTokenName: 'BIFI-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'BifiFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'BifiFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'BIFI',
    pairTokenName2: 'WFTM',
    linkVault: '0x0301a27483E92EbDfAE00b173A48b8eC8568D4fe',
    linkCompounder: '0x8C1BbD5AabA754c8715eB039966b0E9dFa564368',
    finished: false,
    sort: 21,
    closedForStaking: false,
  },
  DeiUSDCSinSPIRITAutocompounder: {
    name: 'DEI-USDC-LP AutoCompounder',
    poolId: 11,
    sectionInUI: 5,
    contract: 'DeiUSDCSinSPIRITAutocompounder',
    depositTokenName: 'DEI-USDC-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'DeiUSDCSinSPIRITVaultAutocompounder',
    autocompounderContract: 'DeiUSDCSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'DEI',
    pairTokenName2: 'USDC',
    linkVault: '0x42D3e161319bd5b014c6b7f90fc34D2324456569',
    linkCompounder: '0xA2519B723c4c2Eee6faB3bf3074c551b61b30E73',
    finished: false,
    sort: 22,
    closedForStaking: false,
  },
  // gOHMFTMSinSPIRITAutocompounder: {
  //   name: 'gOHM-FTM-LP AutoCompounder',
  //   poolId: 12,
  //   sectionInUI: 5,
  //   contract: 'gOHMFTMSinSPIRITAutocompounder',
  //   depositTokenName: 'gOHM-FTM-LP',
  //   earnTokenName: 'SinSPIRIT',
  //   vaultContract: 'gOHMFTMSinSPIRITVaultAutocompounder',
  //   autocompounderContract: 'gOHMFTMSinSPIRITAutocompounder',
  //   masterChefContract: 'SinSPIRITRewardPool',
  //   pairTokenName1: 'gOHM',
  //   pairTokenName2: 'WFTM',
  //   linkVault: '0x2Da99d58553BF60fE5bD755Ea3AC3cAA8582578E',
  //   linkCompounder: '0x714f1b9b581826aa87e885e366d5315d6a676102',
  //   finished: false,
  //   sort: 23,
  //   closedForStaking: false,
  // },
  AtlasFTMSinSPIRITAutocompounder: {
    name: 'ATLAS-FTM-LP AutoCompounder',
    poolId: 13,
    sectionInUI: 5,
    contract: 'AtlasFTMSinSPIRITAutocompounder',
    depositTokenName: 'ATLAS-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'AtlasFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'AtlasFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'ATLAS',
    pairTokenName2: 'WFTM',
    linkVault: '0x500445604Ceac98a5E889B064548BfBE0407Bf8a',
    linkCompounder: '0x4d0Cb4A33663589b4003FbDc83d8cA87D9C507A5',
    finished: false,
    sort: 24,
    closedForStaking: false,
  },
  ScarabGSCARABSinSPIRITAutocompounder: {
    name: 'SCARAB-GSCARAB-LP AutoCompounder',
    poolId: 14,
    sectionInUI: 5,
    contract: 'ScarabGSCARABSinSPIRITAutocompounder',
    depositTokenName: 'SCARAB-GSCARAB-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'ScarabGSCARABSinSPIRITVaultAutocompounder',
    autocompounderContract: 'ScarabGSCARABSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'SCARAB',
    pairTokenName2: 'GSCARAB',
    linkVault: '0x46A551E76e8E9e46D7983707925CF509A07bbDdC',
    linkCompounder: '0xc5f901A80217A82D449d98012270528378E3B723',
    finished: false,
    sort: 25,
    closedForStaking: false,
  },
  DeusFTMSinSPIRITAutocompounder: {
    name: 'DEUS-FTM-LP AutoCompounder',
    poolId: 15,
    sectionInUI: 5,
    contract: 'DeusFTMSinSPIRITAutocompounder',
    depositTokenName: 'DEUS-FTM-LP',
    earnTokenName: 'SinSPIRIT',
    vaultContract: 'DeusFTMSinSPIRITVaultAutocompounder',
    autocompounderContract: 'DeusFTMSinSPIRITAutocompounder',
    masterChefContract: 'SinSPIRITRewardPool',
    pairTokenName1: 'DEUS',
    pairTokenName2: 'WFTM',
    linkVault: '0xF9200499fF5DAA82aEFe1541F764abcB00d68FEB',
    linkCompounder: '0x0b84df4e6974099a3eaf678f4379cb12d5c84f64',
    finished: false,
    sort: 26,
    closedForStaking: false,
  },
};

export default configurations['development'];
// export default configurations[process.env.NODE_ENV || 'development'];
